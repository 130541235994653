<template>

  <receptionnaire-form
    :title="title"
    :submit-button-text="submitButtonText"
    @submitReceptionnaireForm="onSubmit($event)"
  />

</template>

<script>

import router from '@/router'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import ReceptionnaireForm from './ReceptionnaireForm.vue'

export default {
  components: {
    ReceptionnaireForm,
  },
  data() {
    return {
      title: "Ajout d'un réceptionnaire",
      submitButtonText: 'Ajouter',
    }
  },
  setup() {
    const toast = useToast()

    const onSubmit = formValue => store.dispatch('receptionnaire/addReceptionnaire', formValue)
      .then(() => {
        router.push({ name: 'receptionnaires-list' })
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Erreur lors de l'ajout du réceptionnaire",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    return {
      onSubmit,
    }
  },
}
</script>
